/**
* @file modal.js
* @author John Golden and @author Tyler Akin 2020-02-04
*
* This custom functionality is used on the homepage speakers layout and on the
* committees page, as both varieties of layouts utilize the same functionality,
* despite their few ACF differences.
*/

 window.addEventListener('load', function() {
 	var toggles = document.querySelectorAll('.view-bio');
 	var overlay = document.getElementById('overlay');
 	var closeToggles = document.querySelectorAll('.close-modal');
 	var bodyElement = document.querySelector('body');

 	// Create and display assistive text
 	var assistiveTextElement = document.createElement('span');
 	assistiveTextElement.setAttribute('role', 'alert');
 	assistiveTextElement.classList.add('assistive');

 	for ( var i = 0; toggles.length > i; i++ ) {
 		toggles[i].addEventListener('click', function() {
 			// Double parentNode necessary to get up to proper place in DOM
 			var nextElementSibling = this.parentNode.parentNode.nextElementSibling;

 			nextElementSibling.classList.add('open');

 			 this.setAttribute('aria-expanded', 'true');

 			 assistiveTextElement.innerHTML = "Tab forward to enter the lightbox. Assistive Note: Lightbox can be closed by pressing the escape (esc) key or by tabbing forward to the close biography button";

 			 bodyElement.appendChild(assistiveTextElement);

 			overlay.classList.add('active');
 		});
 	}

 	for ( var i = 0; closeToggles.length > i; i++ ) {
 		closeToggles[i].addEventListener('click', function() {
 			assistiveTextElement.innerHTML = "";

 			var directParent = this.parentNode;
 			directParent.classList.remove('open');

 			var relativeToggleButton = directParent.previousElementSibling.querySelector('.view-bio');

 			relativeToggleButton.setAttribute('aria-expanded', 'false');

 			overlay.classList.remove('active');
 		});


 		closeToggles[i].addEventListener('keydown', function(event) {
 			if (event.keyCode === 9) {
 				 assistiveTextElement.innerHTML = "You cannot tab past an open lightbox! Click or press the close button to continue navigating the page";
 				event.preventDefault();
 			}
 		});
 	}

 	// Removes all classes/attributes connected with an open lightbox, thus resulting in a closed lightbox.
 	function hideLightbox() {
 		overlay.classList.remove('active');

 		for ( var i = 0; toggles.length > i; i++ ) {
 			assistiveTextElement.innerHTML = "";

 			overlay.classList.remove('active');
 			toggles[i].parentNode.parentNode.nextElementSibling.classList.remove('open');

 			toggles[i].setAttribute('aria-expanded', 'false');
 		}
 	}

 	overlay.addEventListener('click', function() {
 		hideLightbox();
 	})


 	window.addEventListener('keydown', function(event) {
 		if (event.keyCode === 27) {
 			hideLightbox();
 		}
 		return;
 	});
 });
